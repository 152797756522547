

*{
  margin: 0px;
  padding: 0px;
  font-family: "Noto Sans Arabic", sans-serif;
}
html {
  scroll-behavior: smooth;
}
