@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* * {
  direction: var(--direction);
} */

.react-tel-input {
  @apply h-[40px]
}
 .react-tel-input .form-control {
  
  @apply bg-gray-200 w-full border-none !important;
}
.react-tel-input .selected-flag .arrow {
  @apply rtl:left-0 right-[20px]
}

.react-tel-input .selected-flag {
  @apply rtl:pr-[8px] hover:bg-gray-200 !important; 
}
.react-tel-input .flag-dropdown {
  @apply border-none bg-gray-200 hover:bg-gray-200 rounded-[8px] !important
}


select:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
